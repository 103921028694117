<template> 
  <!-- 阳性 -->
  <el-form :model="positiveCaseForm" :ref="`positiveCaseFormRef${sort}`" :rules="positiveCaseInfoRules"
    label-width="180px">
    <div class="content-item">
      <el-form-item class="formitem" label="姓名：" prop="relationName">
        <!-- <el-input class="select-width" v-model="positiveCaseForm.relationName" placeholder="请输入姓名" /> -->



        <!-- <el-select ref="zdm" clearable filterable allow-create remote :remote-method="relationList" v-model="positiveCaseForm.relationName">
          <el-option v-for="(item,index) in typepopSelect11" :key="item.id" :label="item.zdm" :value="item.relationName"
            @click.native="echo(item,index)"></el-option>
        </el-select> -->

        <el-select ref="zdm" clearable filterable allow-create remote :remote-method="relationList" v-model="positiveCaseForm.relationName" placeholder="请输入姓名">
            <el-option
              v-for="(item,index) in typepopSelect11"
              :key="item.id"
              :label="item.relationName"
              :value="item.relationName"
              @click.native="echo(item,index)">
              <span style="float: left">{{ item.relationName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.relationCardNo }}</span>
            </el-option>
        </el-select>



      </el-form-item>
      <el-form-item class="formitem" label="身份证号：">
        <el-input class="select-width" v-model="positiveCaseForm.relationCardNo" placeholder="请输入证件号" />
      </el-form-item>
    </div>
    <div class="content-item" v-if="isdq">
      <el-form-item class="formitem" label="所属地区：" prop="relationAddressCode">
        <el-cascader v-show="echoAddress" :ref="`relationAddressRef${positiveCaseForm.sort}`" class="select-width"
          v-model="positiveCaseForm.relationAddressCode" :props="props" @change="handelRelationAddress" clearable>
        </el-cascader>
      </el-form-item>
      <el-form-item class="formitem" prop="probabilityRelation" v-if="isboxg">
        <el-checkbox v-model="positiveCaseForm.probabilityRelation" class="select-width"
          @change="changecheckbox">可能性最大的关联者(阳性)
        </el-checkbox>
      </el-form-item>
    </div>
    <div class="content-item">
      <el-form-item class="formitem" label="发病/核酸检测阳性日期：" prop="dateOfOnset">
        <el-date-picker class="select-width" v-model="positiveCaseForm.dateOfOnset" type="date" placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="formitem" label="接诊医院/核酸检测阳性单位：" prop="receivingOrg">
        <el-input v-model="positiveCaseForm.receivingOrg" placeholder="请输入" class="select-width" />
      </el-form-item>
    </div>
    <div class="content-item">
      <el-form-item class="special" label="病例分类：" prop="caseType">
        <el-radio-group v-model="positiveCaseForm.caseType" class="select-width">
          <el-radio v-for="(item, index) in CASE_TYPE" :key="index" :label="item.value">{{ item.label }}</el-radio>
          <template v-if="positiveCaseForm.caseType == 4">
            <el-input v-model="positiveCaseForm.caseTypeOther" placeholder="请输入" class="other-input" />
          </template>
        </el-radio-group>
      </el-form-item>
    </div>
    <div style="text-align: center; height: 40px" @click="showRelationInfo">
      <span>与调查对象的关联信息</span>
      <i class="el-icon-arrow-up" v-show="showRelationInfoFlag"
        style="margin-left: 10px; font-weight: bold; font-size: 20px"></i>
      <i class="el-icon-arrow-down" v-show="!showRelationInfoFlag"
        style="margin-left: 10px; font-weight: bold; font-size: 20px"></i>
    </div>
    <div v-show="showRelationInfoFlag">
      <div class="content-item">
        <el-form-item class="special" label="关系：" prop="relationType">
          <el-radio-group v-model="positiveCaseForm.relationType" class="select-width">
            <el-radio v-for="(item, index) in RELATION_TYPE" :key="index" :label="item.value">{{ item.label }}
            </el-radio>
            <el-input v-show="positiveCaseForm.relationType == 4" v-model="positiveCaseForm.relationTypeOther"
              placeholder="请输入" class="other-input" />
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="formitem" label="最早接触时间：" prop="firstContactTime">
          <el-date-picker class="select-width" v-model="positiveCaseForm.firstContactTime" type="datetime"
            placeholder="请选择时间" format="yyyy-MM-dd HH:mm"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="istimegbg" class="formitem" label="最后接触时间：" prop="lastContactTime">
          <el-date-picker class="select-width" v-model="positiveCaseForm.lastContactTime" type="datetime"
            placeholder="请选择时间" format="yyyy-MM-dd HH:mm" @change="alert"></el-date-picker>
        </el-form-item>

        <el-form-item v-if="!istimegbg" class="formitem" label="最后接触时间：">
          <el-date-picker class="select-width" v-model="positiveCaseForm.lastContactTime" type="datetime"
            placeholder="请选择时间" format="yyyy-MM-dd HH:mm" @change="alert"></el-date-picker>
        </el-form-item>

      </div>
      <div class="content-item">
        <el-form-item class="special" label="接触频率：" prop="contactFrequencyType">
          <el-radio-group v-model="positiveCaseForm.contactFrequencyType" class="select-width">
            <el-radio v-for="(item, index) in CONTACT_FREQUENCY_TYPE" :key="index" :label="item.value">{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="接触地点：" prop="contactAddress">
          <el-radio-group v-model="positiveCaseForm.contactAddress" class="select-width">
            <el-radio v-for="(item, index) in CONTACT_ADDRESS" :key="index" :label="item.value">{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="接触方式：" prop="contactType">
          <el-radio-group v-model="positiveCaseForm.contactType" class="select-width">
            <el-radio v-for="item in CONTACT_TYPE" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item style="width: 90%" label="同交通工具填写：" prop="vehicle">
          <el-input v-model="positiveCaseForm.vehicle" placeholder="请输入" style="width: 50%" />注：（车牌、车次号、航班号与座位号，例：D931
          12车12座）
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item style="width: 60%" label="单次暴露时间：" prop="singleExposureTime">
          <el-input v-model="positiveCaseForm.singleExposureTime" placeholder="请输入" class="select-width" />分钟
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>
<script>
import { isIDCard } from "@/utils/validate";
import { configKey } from "../../../../api/SystemManagement/isolationpointmanagement";
import { relationList } from "@/api/DiseaseControlManagement/DiseaseControlManagement"; 
import { http } from "@/api/index";
import {
  CASE_TYPE,
  RELATION_TYPE,
  CONTACT_FREQUENCY_TYPE,
  CONTACT_ADDRESS,
  CONTACT_TYPE,
} from "@/common/constants";
export default {
  props: ["gsoncrowdType"],
  name: "PositiveCaseForm",
  data() {
    const cardNocheck = (rule, value, callback) => {
      if (!isIDCard(value)) {
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };
    return {
      CASE_TYPE :[],
      RELATION_TYPE :[],
      CONTACT_FREQUENCY_TYPE :[],
      CONTACT_ADDRESS :[],
      CONTACT_TYPE :[],
      positiveCaseForm: this.formData,
      isPR: false,
      isdq: true,
      istimegbg: false,
      isboxg: false,
      typepopSelect11: [],
      showRelationInfoFlag: true,
      echoAddress: false,
      positiveCaseInfoRules: {
        relationName: [
          {
            required: true,
            message: "请输入关联阳性病例姓名",
            trigger: "blur",
          },
        ],
        lastContactTime: [
          {
            required: true,
            message: "请输入最后接触时间",
            trigger: "blur",
          },
        ],
        relationCardNo: [
          {
            required: true,
            message: "请输入关联阳性病例身份证号",
            trigger: "blur",
          },
          { validator: cardNocheck, trigger: "blur" },
        ],
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  props: {
    sort: {
      type: Number,
      default: 1,
    },
    formData: {
      type: Object,
      default: function () {
        return {
          type: 0,
          sort: 1,
          relationName: "",
          relationCardType: "1",
          relationCardNo: "",
          relationAddress: "",
          relationAddressCode: "",
          probabilityRelation: "",
          dateOfOnset: "",
          receivingOrg: "",
          caseType: "",
          caseTypeOther: "",
          relationType: "",
          relationTypeOther: "",
          firstContactTime: "",
          lastContactTime: "",
          contactFrequencyType: "",
          contactAddress: "",
          contactType: "",
          vehicle: "",
          singleExposureTime: "",
        };
      },
    },
  },
  watch: {
    "formData.relationAddressCode": {
      handler: function (newVal) {
        if (newVal && typeof newVal == "string" && this.echoAddress == false) {
          this.positiveCaseForm.relationAddressCode = newVal.split(",");
        }
        this.echoAddress = true;
        // console.log(
        //   " this.positiveCaseForm.relationAddressCode",
        //   this.positiveCaseForm.relationAddressCode
        // );
      },
      immediate: true,
    },
    // formData: {
    //   handler: function (newVal) {
    //     this.positiveCaseForm = newVal;
    //   },
    //   deep: true,
    // },
  },
  created() {
    console.log(this.positiveCaseForm,'this.positiveCaseForm===>>>>')
    let typep = localStorage.getItem("bdtype");
    let threeDetail = localStorage.getItem('arrsanji'); //取出对应的三级本地编码
    this.positiveCaseForm.relationAddressCode = JSON.parse(threeDetail); //赋值三级编码

    let threearrDetail = localStorage.getItem("threearr"); //对应的文字三级
    this.positiveCaseForm.relationAddress = JSON.parse(threearrDetail); //赋值文字


    // if (
    //   this.sort == 1 &&
    //   this.positiveCaseForm.gsoncrowdType != 7 &&
    //   this.positiveCaseForm.probabilityRelation == "" &&
    //   typep == "edit"
    // ) {
    //   this.isPR = true;
    // }
    if (this.sort != 1 || typep != "edit" ) {
      this.isPR = true;
    }  else {
      this.isPR = this.positiveCaseForm.probabilityRelation;
    }

    // 人群分类为密切接触医学观察时，新增关联阳性病例信息时，最后接触时间必须填写
    if (
      this.positiveCaseForm.gsoncrowdType == 6
      &&
      typep == "add"
    ) {
      this.istimegbg = true
      this.isboxg = true
    }

    if (this.positiveCaseForm.relationAddressCode == "") {
      this.positiveCaseForm.relationAddress = "";

      this.positiveCaseForm.relationAddressCode = [
      // '500000000000', '500100000000', '500115000000'
      ];
      this.getfouradress();
      this.getdetailadress();
    }
    //常量
    this.CASE_TYPE = CASE_TYPE;
    this.RELATION_TYPE = RELATION_TYPE;
    this.CONTACT_FREQUENCY_TYPE = CONTACT_FREQUENCY_TYPE;
    this.CONTACT_ADDRESS = CONTACT_ADDRESS;
    this.CONTACT_TYPE = CONTACT_TYPE;
  },
  mounted() {
    console.log("🚀 ~传给老子的次密(阳性)", this.positiveCaseForm.gsoncrowdType);

    // 人群分类为次密切接触医学观察时，不显示
    if (
      this.positiveCaseForm.gsoncrowdType == 7
    ) {
      this.isboxg = false
    } else {
      this.isboxg = true
    }
  },
  methods: {

    // 获取对应的地址四级编码
    async getfouradress() {
      let configkey = 'default_province_town_code';
      let result = await configKey(configkey);
      if (result.data.code == '200') {
        let arr = result.data.data.split(',');
        // let arrsanji = arr.slice(0, 3);//取出前三个编码
        // this.positiveCaseForm.relationAddressCode = arrsanji;
        // console.log(this.positiveCaseForm.relationAddressCode)
      }
    },

    // 获取对应的地址内容
    async getdetailadress() {
      let configkey = 'default_province_town_name';
      let result = await configKey(configkey);
      if (result.data.code == '200') {
        let arr = result.data.data.split(',');
        // arr = arr.slice(0, 3); //取前三个
        // let threearr = arr.join(',')
        // this.positiveCaseForm.relationAddress = threearr;
        this.$forceUpdate();
      }
    },

    //关联信息下拉或收起
    showRelationInfo() {
      this.showRelationInfoFlag = !this.showRelationInfoFlag;
    },
    //选择所属地区
    handelRelationAddress(value) {
      let ref = `relationAddressRef${this.positiveCaseForm.sort}`;
      let node = this.$refs[ref].getCheckedNodes()[0];
      if (node) {
        let relationAddress = node.pathLabels.toString();
        this.positiveCaseForm.relationAddress = relationAddress;
        this.positiveCaseForm.relationAddressCode = value;
      }
    },
    //最后接触时间警告
    alert() {
      this.$alert("请确认好最后接触时间是否准确", "警告", {
        type: "warning",
      });
    },
    // 重置
    reset() {
      console.log("resey");
      this.positiveCaseForm.relationAddress = "";
      this.positiveCaseForm.caseTypeOther = "";
      this.$refs[`positiveCaseFormRef${this.sort}`].resetFields();
    },
    //提交
    commit() {
      return this.$refs[`positiveCaseFormRef${this.sort}`].validate();
    },
    // 人名信息
    async relationList(i) {
      console.log("iiiiiiiiiiiiiiiiii", i);
      let params = {
        relationName: i,
      };

      try {
        let { data } = await relationList({ params });
        console.log("relationList -> data", data);

        if (data.code == 200) {
          if (data.data.length == 0) {
            this.positiveCaseForm.relationName = i;
          } else {
            let hh = [];
            for (let i in data.data) {
              data.data[
                i
              ].zdm = `${data.data[i].relationName} ${data.data[i].relationCardNo} `;
              if (data.data[i].type == 0) {
                hh.push(data.data[i]);
              }
            }

            this.typepopSelect11 = hh;
          }
        }
      } catch (error) { }
    },
    // 回显
    echo(v,index) {
      console.log("输出echo -> v，index", v,index);
      console.log(v,index,'选择完成时触发')
      this.positiveCaseForm.relationName = v.relationName;
      console.log(this.positiveCaseForm.relationName,'选择完成时触发打印this.positiveCaseForm.relationName')
      this.isdq = false;
      this.positiveCaseForm = [];
      // console.log("echo -> v关联阳性病例信息", v);
      this.positiveCaseForm = v;
      let a = this.positiveCaseForm.relationAddressCode;
      // console.log("echo -> a", a);
      if (typeof a == "string") {
        // console.log("为字符串");
        this.positiveCaseForm.relationAddressCode = a.split(",");
      }


      if (this.positiveCaseForm.probabilityRelation == true||this.positiveCaseForm.probabilityRelation == false||this.positiveCaseForm.probabilityRelation == "1"||this.positiveCaseForm.probabilityRelation == "0") {
        this.positiveCaseForm.probabilityRelation = true;
      }

      // if (this.positiveCaseForm.probabilityRelation == true) {
      //   this.positiveCaseForm.probabilityRelation = true;
      // } else {
      //   this.positiveCaseForm.probabilityRelation = false;
      // }
      // if (this.sort == 1) {
      //   this.isPR = true;
      //   this.positiveCaseForm.probabilityRelation = true;
      // }
      this.positiveCaseForm.relationAddress = "";
      this.positiveCaseForm.relationAddress = a.relationAddress;
      console.log("echo ->  this.positiveCaseForm", this.positiveCaseForm);
      this.isdq = true;

      this.$forceUpdate();
    }, 
    changecheckbox(v) {
      console.log(v, 'checkbox')
      // if (
      //   this.positiveCaseForm.gsoncrowdType == 7 &&
      //   this.positiveCaseForm.gsoncrowdType == 6
      // ) {
      //   }
      this.$emit("onenmb", "123");

      if (v == true) {
        this.positiveCaseForm.probabilityRelation = true;
      } else {
        this.positiveCaseForm.probabilityRelation = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-width {
  width: 80%;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formitem {
  width: 50%;
}

.special {
  flex: 1;
}

.other-input {
  width: 200px;
  margin-left: 10px;
}
</style>