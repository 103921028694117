<template>
  <!-- 关联密切接触者（一密 --> 
  <el-form :model="closeContactForm" :key="closeContactForm.sort" :ref="`closeContactFormRef${sort}`"
    :rules="closeContactFormRules" label-width="180px">
    <div class="content-item">
      <el-form-item class="formitem" label="姓名：" prop="relationName">

        <!-- <el-select clearable filterable remote :remote-method="relationList" v-model="closeContactForm.relationName">
          <el-option v-for="item in typepopSelect11" :key="item.id" :label="item.zdm" :value="item.relationName"
            @click.native="echo(item)"></el-option>
        </el-select> -->


        <el-select ref="zdm" clearable filterable allow-create remote :remote-method="relationList" v-model="closeContactForm.relationName" placeholder="请输入姓名">
            <el-option
              v-for="(item,index) in typepopSelect11"
              :key="item.id"
              :label="item.relationName"
              :value="item.relationName"
              @click.native="echo(item,index)">
              <span style="float: left">{{ item.relationName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.relationCardNo }}</span>
            </el-option>
        </el-select>

        <!-- <el-input class="select-width" v-model="closeContactForm.relationName" placeholder="请输入姓名"></el-input> -->
      </el-form-item>
      <el-form-item class="formitem" label="身份证号：" prop>
        <el-input class="select-width" v-model="closeContactForm.relationCardNo" placeholder="请输入证件号" />
      </el-form-item>
    </div>
    <div class="content-item">
      <el-form-item class="formitem" label="所属地区：">
        <el-cascader v-show="echoAddress" :ref="`relationAddressRef${closeContactForm.sort}`"
          v-model="closeContactForm.relationAddressCode" class="select-width" :props="props"
          @change="handelRelationAddress" clearable></el-cascader>
      </el-form-item>
      <el-form-item class="formitem">

        <el-checkbox v-model="closeContactForm.probabilityRelation" @change="changecheckbox" :max="1"
          class="select-width">
          可能性最大的关联者（一密）
        </el-checkbox>
      </el-form-item>
    </div>
    <div style="text-align: center; height: 40px" @click="showRelationInfo">
      <span>与调查对象的关联信息</span>
      <i class="el-icon-arrow-up" v-show="showRelationInfoFlag"
        style="margin-left: 10px; font-weight: bold; font-size: 20px"></i>
      <i class="el-icon-arrow-down" v-show="!showRelationInfoFlag"
        style="margin-left: 10px; font-weight: bold; font-size: 20px"></i>
    </div>
    <div v-show="showRelationInfoFlag">
      <div class="content-item">
        <el-form-item class="special" label="关系：">
          <el-radio-group v-model="closeContactForm.relationType" class="select-width">
            <el-radio v-for="(item, index) in RELATION_TYPE" :key="index" :label="item.value">{{ item.label }}
            </el-radio>
            <el-input v-show="closeContactForm.relationType == 4" v-model="closeContactForm.relationTypeOther"
              placeholder="请输入" class="other-input" />
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="formitem" label="最早接触时间：">
          <el-date-picker class="select-width" v-model="closeContactForm.firstContactTime" type="datetime"
            placeholder="请选择时间" format="yyyy-MM-dd HH:mm"></el-date-picker>
        </el-form-item>
        <el-form-item class="formitem" label="最后接触时间：" prop="lastContactTime">
          <el-date-picker class="select-width" v-model="closeContactForm.lastContactTime" type="datetime"
            placeholder="请选择时间" format="yyyy-MM-dd HH:mm" @change="alert"></el-date-picker>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="接触频率：">
          <el-radio-group v-model="closeContactForm.contactFrequencyType" class="select-width">
            <el-radio v-for="(item, index) in CONTACT_FREQUENCY_TYPE" :key="index" :label="item.value">{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="接触地点：">
          <el-radio-group v-model="closeContactForm.contactAddress" class="select-width">
            <el-radio v-for="(item, index) in CONTACT_ADDRESS" :key="index" :label="item.value">{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="接触方式：">
          <el-radio-group v-model="closeContactForm.contactType" class="select-width">
            <el-radio v-for="item in CONTACT_TYPE" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item style="width: 90%" label="同交通工具填写：">
          <el-input v-model="closeContactForm.vehicle" placeholder="请输入" style="width: 50%" />注：（车牌、车次号、航班号与座位号，例：D931
          12车12座）
        </el-form-item>
      </div>
      <div class="content-item">
        <el-form-item style="width: 60%" label="单次暴露时间：">
          <el-input v-model="closeContactForm.singleExposureTime" placeholder="请输入" class="select-width" />分钟
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>
<script>
import { isIDCard } from "@/utils/validate";
import { http } from "@/api/index";
import { relationList } from "@/api/DiseaseControlManagement/DiseaseControlManagement";

import {
  CASE_TYPE,
  RELATION_TYPE,
  CONTACT_FREQUENCY_TYPE,
  CONTACT_ADDRESS,
  CONTACT_TYPE,
} from "@/common/constants";
export default {
  props: ["editableTabs"],
  name: "CloseContactForm",
  data() {
    const cardNocheck = (rule, value, callback) => {
      if (!isIDCard(value)) {
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };
    return {
      CASE_TYPE :[],
      RELATION_TYPE :[],
      CONTACT_FREQUENCY_TYPE :[],
      CONTACT_ADDRESS :[],
      CONTACT_TYPE :[],
      typepopSelect11: [],
      closeContactForm: this.formData,
      isPR: false,
      showRelationInfoFlag: true,
      echoAddress: false,
      closeContactFormRules: {
        relationName: [
          {
            required: true,
            message: "请输入密接者姓名",
            trigger: "blur",
          },
        ],
        lastContactTime: [
          {
            required: true,
            message: "请输入最后接触时间",
            trigger: "blur",
          },
        ],
        relationCardNo: [
          {
            required: true,
            message: "请输入密接者身份证号",
            trigger: "blur",
          },
          { validator: cardNocheck, trigger: "blur" },
        ],
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  mounted(){
    // console.log(this.formData,99999);
  },
  props: {
    sort: {
      type: Number,
      default: 1,
    },
    formData: {
      type: Object,
      default: function () {
        return {
          type: 1,
          sort: 1,
          relationName: "",
          relationCardType: "1",
          relationCardNo: "",
          relationAddress: "",
          relationAddressCode: [],
          probabilityRelation: "",
          dateOfOnset: "",
          receivingOrg: "",
          caseType: "",
          caseTypeOther: "",
          relationType: "",
          relationTypeOther: "",
          firstContactTime: "",
          lastContactTime: "",
          contactFrequencyType: "",
          contactAddress: "",
          contactType: "",
          vehicle: "",
          singleExposureTime: "",
        };
      },
    },
  },
  watch: {
    "formData.relationAddressCode": {
      handler: function (newVal) {
        // console.log("newVal", newVal);

        if (newVal && typeof newVal == "string" && this.echoAddress == false) {
          this.closeContactForm.relationAddressCode = newVal.split(",");
        }
        this.echoAddress = true;
      },
      immediate: true,
    },
    // formData: {
    //   handler: function (newVal) {
    //     this.closeContactForm = newVal;
    //   },
    //   deep: true,
    // },
  },
  created() {
    let typep = localStorage.getItem("bdtype");
    // console.log(this.sort);
    // console.log(this.closeContactForm.probabilityRelation);
    // console.log(typep);
    // if (
    //   this.sort == 1 &&
    //   this.closeContactForm.probabilityRelation==true &&
    //   typep == "edit"
    // ) {
    //   // console.log('我走了');
    //   this.isPR = true;
    // }
    // if (this.sort != 1 || typep != "edit") {
    //   this.isPR = true;
    // }  else {
    //   this.isPR = this.closeContactForm.probabilityRelation;
    // }


    let threeDetail = localStorage.getItem('arrsanji')
    // console.log(threeDetail,'threeDetailthreeDetailthreeDetail输出三级')
    this.closeContactForm.relationAddressCode = JSON.parse(threeDetail);
    // if (this.closeContactForm.relationAddressCode == "") {
    //   this.closeContactForm.relationAddress = "重庆市,市辖区,长寿区";

    //   this.closeContactForm.relationAddressCode = [
    //   '500000000000', '500100000000', '500115000000'
    //   ];
    // }


    //常量
    this.CASE_TYPE = CASE_TYPE;
    this.RELATION_TYPE = RELATION_TYPE;
    this.CONTACT_FREQUENCY_TYPE = CONTACT_FREQUENCY_TYPE;
    this.CONTACT_ADDRESS = CONTACT_ADDRESS;
    this.CONTACT_TYPE = CONTACT_TYPE;
  },
  methods: {
    //关联信息下拉或收起
    showRelationInfo() {
      this.showRelationInfoFlag = !this.showRelationInfoFlag;
    },
    //选择所属地区
    handelRelationAddress(value) {
      let ref = `relationAddressRef${this.closeContactForm.sort}`;
      let node = this.$refs[ref].getCheckedNodes()[0];
      if (node) {
        let relationAddress = node.pathLabels.toString();
        this.closeContactForm.relationAddress = relationAddress;
        this.closeContactForm.relationAddressCode = value;
      }
    },
    //最后接触时间警告
    alert() {
      this.$alert("请确认好最后接触时间是否准确", "警告", {
        type: "warning",
      });
    },
    // 重置
    reset() {
      this.closeContactForm.relationAddress = "";
      this.closeContactForm.caseTypeOther = "";
      this.$refs[`closeContactFormRef${this.sort}`].resetFields();
    },
    //提交
    commit() {
      return this.$refs[`closeContactFormRef${this.sort}`].validate();
    },
    // 人名信息
    async relationList(i) {
      // console.log("i", i);
      let params = {
        relationName: i,
      };

      try {
        let { data } = await relationList({ params });
        // console.log("relationList -> data", data);

        if (data.code == 200) {
          if (data.data.length == 0) {
            this.closeContactForm.relationName = i;
          } else {
            let hh = [];
            for (let i in data.data) {
              data.data[
                i
              ].zdm = `${data.data[i].relationName} ${data.data[i].relationCardNo} `;
              if (data.data[i].type == 1) {
                hh.push(data.data[i]);
              }
            }

            this.typepopSelect11 = hh;
          }
        }
      } catch (error) { }
    },
    // 回显
    async echo(v,index) {
      console.log("输出echo -> v，index", v,index);
      this.closeContactForm.relationName = v.relationName
      this.closeContactForm = v;
      let a = this.closeContactForm.relationAddressCode;
      if (typeof a == "string") {
        this.closeContactForm.relationAddressCode = a.split(",");
      }

      if (this.closeContactForm.probabilityRelation == 1||this.closeContactForm.probabilityRelation == 0||this.closeContactForm.probabilityRelation == true||this.closeContactForm.probabilityRelation == false) {
        this.closeContactForm.probabilityRelation = true;
      }

      // if (this.closeContactForm.probabilityRelation == 1) {
      //   this.closeContactForm.probabilityRelation = true;
      // } else {
      //   this.closeContactForm.probabilityRelation = false;
      // }
      // if (this.sort == 1) {
      //   this.isPR = false;
      //   this.closeContactForm.probabilityRelation = true;
      // }
    },
    changecheckbox(v) {
      // console.log(
      //   "🚀 ~ file: CloseContactForm.vue ~ line 413 ~ changecheckbox ~ v",
      //   v
      // );
      // if (
      //   this.closeContactForm.gsoncrowdType == 7 &&
      //   this.closeContactForm.gsoncrowdType == 6
      // ) {
      //   }
      this.$emit("onenmb", "123");
      if (v == true) {
        this.closeContactForm.probabilityRelation = true;
        this.isPR = true;
      } else {
        this.closeContactForm.probabilityRelation = false;
        this.isPR = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-width {
  width: 80%;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formitem {
  width: 50%;
}

.special {
  flex: 1;
}

.other-input {
  width: 200px;
  margin-left: 10px;
}
</style>